<template>
  <div class="login mt-28">
    <h2>Welcome to Aura Magazine's Look Book platform</h2>
    <p class="my-4">Login to your account</p>
    <a-form layout="vertical" :model="credentials" ref="formRef" :rules="rules">
      <a-form-item label="Email:" name="email">
        <a-input
          v-model:value="credentials.email"
          placeholder="dropcode@gmail.com"
        />
      </a-form-item>
      <a-form-item label="Password" ref="password" name="password">
        <a-input-password
          v-model:value="credentials.password"
          placeholder="Enter Password"
          @keypress.enter="handleLogin"
        />
      </a-form-item>
      <a-row type="flex" justify="space-between">
        <a-col>
          <router-link :to="{ name: 'Register' }"
            >Don't have an account?</router-link
          >
        </a-col>
        <a-col>
          <router-link :to="{ name: 'ForgotPassword' }"
            >Forgot Password?</router-link
          >
        </a-col>
      </a-row>
      <a-form-item class="mt-10">
        <app-button
          buttonText="Login"
          buttonClass="w-full"
          :loading="requestLoading"
          @buttonAction="handleLogin"
        />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import { useStore } from 'vuex'
import useLoadingState from '@/use/loading-state'
export default {
  setup() {
    // external composition functions
    const { requestLoading } = useLoadingState()
    // end of composition functions
    const store = useStore()
    // local state
    const state = reactive({
      credentials: {
        email: '',
        password: ''
      }
    })
    // end of local state
    const formRef = ref()
    // validation rules
    const rules = {
      email: [
        {
          required: true,
          message: 'Please add a registered username to login.',
          trigger: 'blur'
        },
        {
          type: 'email',
          message: 'Please add a valid email.',
          trigger: 'change'
        }
      ],
      password: [
        {
          required: true,
          message: 'Please add a registered password to login.',
          trigger: 'blur'
        },
        {
          min: 6,
          max: 30,
          message:
            'Passwords must have atleast 6 characters and maximum of 30 characters.',
          trigger: 'blur'
        }
      ]
    }
    // methods
    const handleLogin = () => {
      // console.log('i got clicked')
      formRef.value
        .validate()
        .then(() => {
          store.dispatch('auth/LoginUser', state.credentials)
        })
        .catch((error) => {
          console.log('error during validation', error)
        })
    }

    // return variables and functions to be used within the template
    return { ...toRefs(state), handleLogin, rules, formRef, requestLoading }
  }
}
</script>

<style lang="scss" scoped></style>
